let nav = {
    // 数据
    state: {
        boole: null
    },
    mutations: {
        UP_BOOLE(state) {
            // console.log(state);
            state.boole = false;
        },
        UPP_BOOLE(state) {
            state.boole = true;
        }
    }
}

// 暴露
export default nav