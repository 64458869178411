<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  mounted(){
  },
  destroyed(){
    localStorage.clear();
  }
}
</script>
<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

* {
  box-sizing: border-box;
}
</style>
