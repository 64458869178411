import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: "/",
    redirect: 'index'
  },
  // 登录login
  {
    path: '/login1',
    name: 'login1',
    component: () => import("@/components/logins/view/login1.vue"),
    redirect: {
      name: 'logins'
    },
    children: [
      // 登录
      {
        path: '/logins',
        name: 'logins',
        component: () => import("@/components/logins/view/logins.vue"),
      },
      //注册
      {
        path: '/reg',
        name: 'reg',
        component: () => import("@/components/logins/view/reg.vue"),
      },
      //忘记密码
      {
        path: '/forget',
        name: 'forget',
        component: () => import("@/components/logins/view/forget.vue"),
      },
    ]
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue"),
    redirect: {
      name: 'indexPage'
    },
    children: [
      // {
      //   path: '/IndexxPage2',
      //   name: 'IndexxPage2',
      //   component: () => import("@/views/Home/IndexxPage2"),
      // },
      {
        path: '/indexPage',
        name: 'indexPage',
        component: () => import("@/views/Home/IndexPage.vue"),
      },
      // 二大赛道
      {
        path: '/competition',
        name: 'indeTwo',
        component: () => import("@/views/Home/IndexxPage2.vue"),
        props: { sidebar: false },
      },
      // 三大赛道
      {
        path: '/indexHome',
        name: 'indexHome',
        component: () => import("@/views/Page/indexPage.vue"),
        props: { sidebar: false },
      },
      {
        path: '/Page',
        name: 'Page',
        component: () => import("@/views/Page/Page.vue"),
        props: { sidebar: false },
        redirect: {
          name: 'PPThome'
        },
        children: [
          {
            path: '/PPThome',
            name: 'PPThome',
            component: () => import("@/views/Page/PPThome.vue"),
            props: { sidebar: false }
          },
          {
            path: '/Pagedetails',
            name: 'Pagedetails',
            component: () => import("@/views/Page/Pagedetails.vue"),
            props: { sidebar: false }
          },
        ]
      },
      // 个人中心
      {
        path: '/Personalcenter',
        name: 'Personalcenter',
        component: resolve => require(['@/views/Page/Personalcenter.vue'], resolve),
        props: { sidebar: false },
        redirect: { name: 'Downloadlatest' },
        children: [
          //我的下载
          {
            path: '/Downloadlatest',
            name: 'Downloadlatest',
            component: resolve => require(['@/views/Page/Downloadlatest.vue'], resolve),
            props: { sidebar: false }
          },
          //我的收藏
          {
            path: '/Mycollection',
            name: 'Mycollection',
            component: resolve => require(['@/views/Page/Mycollection.vue'], resolve),
            props: { sidebar: false }
          },
          //消息中心
          {
            path: '/Messagecenter',
            name: 'Messagecenter',
            component: resolve => require(['@/views/Page/Messagecenter.vue'], resolve),
            props: { sidebar: false }
          },
          //兑换码
          {
            path: '/Conversioncode',
            name: 'Conversioncode',
            component: resolve => require(['@/views/Page/Conversioncode.vue'], resolve),
            props: { sidebar: false }
          },
          //个人信息
          {
            path: '/Perstion',
            name: 'Perstion',
            component: resolve => require(['@/views/Page/Perstion.vue'], resolve),
            props: { sidebar: false }
          },
          //修改密码
          {
            path: '/Chapassword',
            name: 'Chapassword',
            component: resolve => require(['@/views/Page/Chapassword.vue'], resolve),
            props: { sidebar: false }
          },
        ]
      },
      // 详情页
      {
        path: '/Detailspage',
        name: 'Detailspage',
        component: resolve => require(['@/views/Page/Detailspage.vue'], resolve),
        props: { sidebar: true }
      },
      // zzh详情页
      {
        path: '/details',
        name: 'details',
        component: resolve => require(['@/views/Home/details.vue'], resolve),
        props: { sidebar: true }
      },
      // 搜索页
      {
        path: '/SearchPage',
        name: 'SearchPage',
        component: resolve => require(['@/views/Page/SearchPage.vue'], resolve),
        props: { sidebar: true }
      },
      // 关于我们
      {
        path: "/Contactus",
        name: "Contactus",
        component: () => import("@/views/Page/Contactus.vue"),
        props: { sidebar: true }
      },
    ],
  },
  {
    path: '/1',
    name: '1',
    component: () => import("@/views/1.vue"),
  },
  {
    path: '/2',
    name: '2',
    component: () => import("@/views/2.vue"),
  },


  // 用户协议
  {
    path: "/Useragreement",
    name: "Useragreement",
    component: () => import("@/views/Page/Useragreement.vue"),
    props: { sidebar: true }
  },
  // 使用条款
  {
    path: "/s1",
    name: "s1",
    component: () => import("@/views/s1.vue"),
    props: { sidebar: true }
  },
  // 隐私协议
  {
    path: "/s2",
    name: "s2",
    component: () => import("@/views/s2.vue"),
    props: { sidebar: true }
  },
  //支付
  {
    path: "/orderAlipay",
    name: "orderAlipay",
    component: () => import("@/views/Page/orderAlipay.vue"),
    props: { sidebar: true }
  },
  // 重定向
  // {
  //   path: "/",
  //   redirect: "/2"
  // }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
