import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BetterLogin from "./components/login/login.vue"
import VueCropper from 'vue-cropper';
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.component("BetterLogin",BetterLogin)
import axios from 'axios';
Vue.prototype.axios = axios;
import api from './api/api';
Vue.prototype.axios = api;
Vue.use(ElementUI)
Vue.use(VueCropper);
Vue.config.productionTip = false; 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
