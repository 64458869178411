import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import { Message } from 'element-ui';
import './../styles/icon/iconfont.css'

// const service = axios.create({
//     baseURL:'https://api.ibeisai.com/',
//     timeout: 60 * 1000,
//     headers: {'Content-Type':'application/x-www-form-urlencoded'},
// })
// 配置接口地址
axios.defaults.baseURL='https://api.ibeisai.com/'

// axios.defaults.baseURL='http://192.168.31.98:7070/rselectview/'
//文件上传地址
store.dispatch('uploadimage', "https://api.ke.ibeisai.com/v1/file/upload")
// 请求超时时间
axios.defaults.timeout = 60000
// 请求头设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

//请求拦截器
axios.interceptors.request.use(config => {
    return config;
});   
//响应拦截器
axios.interceptors.response.use(response => {
    const { status } = response;
    if (status === 200) {
        if(response.data.code==-5){
            Message.error({
                message: "登录过期，请重新登录",
                description: "登录过期，请重新登录"
            });
            localStorage.clear()
            location.reload()
            // router.push('/login')
        }
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
}, error => {
    if (error.response.status) {
        switch(error.response.status) {
            // 未登录
            case 401 : 
                break;
            // 登录失效
            case 403 :
                break;
            // Not Found
            case 404 :
                break;
            // 服务器错误
            case 500 :
                break;
            default :
                break;
        }
        return Promise.reject(error.response);
    }
});
// export default service

//get封装方法
export function get(url, params) {
    return new Promise((resolve,reject) => {
        axios.get(url,params).then(res => {
            resolve(res.data)
        }).catch(err=>{
            reject(err.data)
        })
    })
}


//post封装方法
export function post(url, params) {
    return new Promise((resolve,reject) => {
        axios.post(url,params).then(res => {
            resolve(res.data)
        }).catch(err=>{
            reject(err.data)
        })
    })
}


//put封装方法
export function put(url, params) {
    return new Promise((resolve,reject) => {
        axios.put(url,params).then(res => {
            resolve(res.data)
        }).catch(err=>{
            reject(err.data)
        })
    })
}
